
import { defineComponent, ref } from 'vue'
import AuthApi from '@/apis/rota-architect/auth'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { Forgettable } from '@/types/auth'

export default defineComponent({
  setup () {
    const working = ref(false)
    const submissionError = ref('')
    const successMessage = ref('')


    const forgotSchema: yup.ObjectSchema<Forgettable> = yup.object({
      email: yup
        .string()
        .email('Please provide a valid email.')
        .required('This field is required.'),
    }).defined()

    const { handleSubmit } = useForm({
      validationSchema:  forgotSchema,
    })

    const onSubmit = handleSubmit((values, actions) => {
      submissionError.value = ''
      successMessage.value = ''
      working.value = true

      AuthApi.forgotPassword(values)
        .then((res) => {
          successMessage.value = res.data.message
          actions.resetForm()
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data) || 'Bad request'
        })
        .finally(() => {
          working.value = false
        })
    });

    return {
      working,
      onSubmit,
      submissionError,
      successMessage,
    };
  },
})
