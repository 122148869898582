<template>
  <AuthFormWrapper>
    <template #formTitle>
      Forgot your password?
    </template>
    <template #formSubtitle>
      Enter your email below and request a reset.
    </template>

    <form
      @submit="onSubmit"
      class="w-full mt-12 lg:mt-16"
    >
      <BaseErrorMessage 
        v-if="submissionError"
        extend-wrapper-classes="-mt-6 mb-4"
      >
        {{ submissionError }}
      </BaseErrorMessage>
      <BaseSuccessMessage 
        v-if="successMessage"
        extend-wrapper-classes="-mt-6 mb-4"
      >
        {{ successMessage }}
      </BaseSuccessMessage>

      <fieldset :disabled="working">
        <!-- Fields -->
        <VTextInput 
          name="email"
          type="email"
          label="Email Address"
          maxlength="100"
        />
      </fieldset>
      
      <!-- Submit -->
      <BaseButton
        theme="auth"
        size="full"
        type="submit"
        :working="working"
      >
        Request reset
      </BaseButton>

      <!-- Additional Options -->
      <div class="flex justify-center text-gray-400 mt-8 text-sm xl:text-base">
        <RouterLink
          :to="{ name: 'login' }"
          class="hover:underline"
        >
          Back to login
        </RouterLink>
      </div>
    </form>
  </AuthFormWrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AuthApi from '@/apis/rota-architect/auth'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { Forgettable } from '@/types/auth'

export default defineComponent({
  setup () {
    const working = ref(false)
    const submissionError = ref('')
    const successMessage = ref('')


    const forgotSchema: yup.ObjectSchema<Forgettable> = yup.object({
      email: yup
        .string()
        .email('Please provide a valid email.')
        .required('This field is required.'),
    }).defined()

    const { handleSubmit } = useForm({
      validationSchema:  forgotSchema,
    })

    const onSubmit = handleSubmit((values, actions) => {
      submissionError.value = ''
      successMessage.value = ''
      working.value = true

      AuthApi.forgotPassword(values)
        .then((res) => {
          successMessage.value = res.data.message
          actions.resetForm()
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data) || 'Bad request'
        })
        .finally(() => {
          working.value = false
        })
    });

    return {
      working,
      onSubmit,
      submissionError,
      successMessage,
    };
  },
})
</script>